import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { environment } from '@environments/environment';
import { User, Job, CreditPackage } from '@core/models';
import { ApplyToJob, UserState, GetJobQuota, FetchCreditPackages, CreditState } from '@core/states';
import { JobQuotaDTO } from '@core/dto';
import { PaymentsService } from '@core/services';
import { CreditConfirmationModalComponent } from '@src/visitor/modals/components/credit-confirmation-modal/credit-confirmation-modal.component';

@Component({
  selector: 'app-job-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss'],
})
export class ApplyComponent implements OnInit {
  @Output()
  public cancelClicked = new EventEmitter();

  @Input()
  public job: Job;

  @Input()
  public user: User;

  @Select(UserState.user)
  public user$: Observable<User>;

  @Select(UserState.jobQuota)
  public jobQuota$: Observable<JobQuotaDTO>;

  @Select(CreditState.packages)
  public creditPackages$: Observable<CreditPackage[]>;

  public isBusy = false;

  public applied = false;

  public requireVideo = false;

  public haveFreeMembership = true;

  public isProcessBuyCredits = false;

  public requireNote = false;

  public mediaValue: any = null;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<ApplyComponent>,
    private matDialog: MatDialog,
    private store: Store,
    private paymentService: PaymentsService,
  ) {}

  public ngOnInit(): void {
    this.job = this.data.job;
    this.user = this.data.user;

    const userSnapshot = this.store.selectSnapshot(UserState.user);

    this.haveFreeMembership = userSnapshot.membership.price_month === 0;

    this.store.dispatch(new GetJobQuota());
    this.store.dispatch(new FetchCreditPackages());
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public applyJob(): void {
    if (this.job.requestTalentVideo === true) {
      this.requireVideo = true;
    } else {
      this.requireNote = true;
    }
  }

  public videoAction(param: any): void {
    if (param) {
      this.mediaValue = param;
    }
    this.requireVideo = false;
    this.requireNote = true;
  }

  public submitApplyJob(noteValue: any): void {
    let note = '';
    if (!noteValue?.skipNote) {
      note = noteValue?.note;
    }
    this.requireNote = false;
    this.store.dispatch(new ApplyToJob(this.user.id, this.job.id, null, note));
    this.applied = true;
  }

  public upgradeMembership(): void {
    this.matDialog.closeAll();
    this.store.dispatch(new Navigate(['/account/details/membership']));
  }

  public async buyCredit(creditPackage: CreditPackage): Promise<void> {
    // this.isProcessBuyCredits = true;
    // const paymentResult = await this.paymentService
    //   .createCreditPayment(creditPackage.id, `${environment.baseUrl}/find-jobs/job/${this.job.id}?orderBy=created;desc`)
    //   .toPromise();
    // this.isProcessBuyCredits = false;
    // if (!!paymentResult) {
    //   window.location.href = paymentResult.oppTransaction.redirect_url;
    // }
    this.matDialog.open(CreditConfirmationModalComponent, {
      data: {
        creditPackage,
      },
    });
  }

  public get isAllowedToApply(): boolean {
    let genderAllow = true;

    let minimumAge = this.job.startAge;
    let maximumAge = this.job.endAge;

    if (this.job.startAge <= 17 && this.job.endAge >= 18) {
      minimumAge = minimumAge - 5 <= 0 ? 0 : minimumAge - 5;
      maximumAge = maximumAge + 5;
    } else if (this.job.startAge >= 18) {
      //if adult job, the maximu5 should be 18 years old;
      minimumAge = minimumAge - 5 >= 18 ? minimumAge - 5 : 18;
      maximumAge = maximumAge + 5;
    } else {
      minimumAge = minimumAge - 2 > 0 ? minimumAge - 2 : 0;
      maximumAge = maximumAge === 17 ? 17 : maximumAge + 2;
    }

    const ageIsAllowed = this.user.age >= minimumAge && this.user.age <= maximumAge;

    if (this.job.gender) {
      const jobsGender = this.job.gender.split(';');

      if (this.user.transgender && jobsGender.includes('transgender')) genderAllow = true;
      else genderAllow = jobsGender.includes(this.user.gender);
    }

    return genderAllow && ageIsAllowed;
  }
}
