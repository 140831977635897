<ng-container *ngIf="jobApplication">
  <div class="wrapper">
    <app-icon icon="/assets/img/close.svg" class="close" (click)="closeModal()"></app-icon>
    <div class="job-request-card d-flex align-items-center flex-column">
      <!-- Default -->
      <ng-container *ngIf="stateAction === 'DEFAULT'">
        <div class="title" translate>Request to book</div>
        <div class="talent-info">
          <img src="{{jobApplication.user?.photo?.thumbUrl}}" alt="" />
          <p>{{user?.firstName}} {{user?.lastName}}</p>
        </div>
        <div class="desc text-center" translate>You are about to send a booking request</div>

        <div class="desc text-left ml-1" *ngIf="job.bypassPayment">
          <ul>
            <li translate>The Talent can accept or reject your booking request.</li>
            <li translate>
              If you cancel a confirmed booking within 3 days before the shoot date, the Talent may charge 50% of the
              fee, 100% of the fee if canceled within 1 day before the shoot date.
            </li>
          </ul>
        </div>

        <div class="desc text-left ml-1" *ngIf="!job.bypassPayment">
          <ul>
            <li translate>The Talent can accept or reject your booking request.</li>
            <li translate>
              If you cancel a confirmed booking within 3 days before the shoot date, the Talent may charge 50% of the
              fee, 100% of the fee if canceled within 1 day before the shoot date.
            </li>
            <li translate>
              You will be directed to a payment screen where your payment will be held until after the shoot.
            </li>
            <li>
              <span translate>If you would like to receive an invoice afterwards, please email us at</span>
              <span>
                &nbsp;
                <a href="mailto:casters@casterbee.nl">casters&#64;casterbee.nl</a>
                &nbsp;
              </span>
              <span translate>or&nbsp;</span>
              <span><a href="tel:+31-6-4216-6461">+31 6 4216 6461 Jorge Bijleveld.</a></span>
            </li>
          </ul>
        </div>
        <div class="btn-wrap">
          <button (click)="acceptJobApplication()" translate>Yes send now</button>
          <button (click)="editFee()" translate>Edit Fee</button>
        </div>
      </ng-container>
      <!-- Edit -->
      <ng-container *ngIf="stateAction === 'EDIT'">
        <div class="title" translate>Edit Fee</div>
        <div class="talent-info">
          <img src="{{jobApplication.user?.photo?.thumbUrl}}" alt="" />
          <p>{{user?.firstName}} {{user?.lastName}}</p>
        </div>
        <form [formGroup]="form" (ngSubmit)="confirmToSubmit()">
          <div class="control-container">
            <label class="form-label" translate>Total fee:</label>
            <app-text-field
              class="form-control input-euro"
              formControlName="fee"
              type="text"
              min="0"
              max="100000"></app-text-field>
          </div>
          <div class="control-container">
            <label class="form-label" translate>Total buy-out :</label>
            <app-text-field
              class="form-control input-euro"
              formControlName="buyOut"
              type="text"
              min="0"
              max="100000"></app-text-field>
          </div>
          <div class="control-container">
            <label class="form-label" translate>Travel Cost :</label>
            <div class="w-full">
              <div class="checkbox-group">
                <label>
                  <app-radio class="mr-2" [value]="false" formControlName="tc"></app-radio>
                  <span translate>No</span>
                </label>
                <label>
                  <app-radio class="mr-2" [value]="true" formControlName="tc"></app-radio>
                  <span translate>Yes</span>
                </label>
              </div>
            </div>
          </div>
          <div *ngIf="form.get('tc')?.value" class="control-container">
            <div class="form-label"></div>
            <div class="cost-extra-input">
              <div style="display: flex; align-items: center; gap: 5px">
                <app-text-field
                  class="form-control input-euro"
                  formControlName="tcAmount"
                  type="text"
                  min="0"
                  max="100000"></app-text-field>
                p/km
              </div>
              <label>
                <app-checkbox formControlName="isTcMaxAmount" class="mr-2"></app-checkbox>
                <span translate>Max ammount</span>
              </label>
              <app-text-field
                *ngIf="form.get('isTcMaxAmount')?.value"
                class="form-control input-euro"
                formControlName="tcMaxAmount"
                type="text"
                min="0"
                max="100000"></app-text-field>
            </div>
          </div>
          <div class="control-container">
            <label class="form-label" translate>Parking Cost :</label>
            <div class="checkbox-group">
              <label>
                <app-radio class="mr-2" [value]="false" formControlName="pc"></app-radio>
                <span translate>No</span>
              </label>
              <label>
                <app-radio class="mr-2" [value]="true" formControlName="pc"></app-radio>
                <span translate>Yes</span>
              </label>
            </div>
          </div>
          <div *ngIf="form.get('pc')?.value" class="control-container">
            <div class="form-label"></div>
            <div class="cost-extra-input">
              <label>
                <app-checkbox formControlName="isPcMaxAmount" class="mr-2"></app-checkbox>
                <span translate>Max ammount</span>
              </label>
              <app-text-field
                *ngIf="form.get('isPcMaxAmount')?.value"
                class="form-control input-euro"
                formControlName="pcMaxAmount"
                type="text"
                step="0.01"></app-text-field>
            </div>
          </div>
          <div class="btn-wrap">
            <button type="submit" [disabled]="form.invalid">{{'Send' | translate}}</button>
            <button
              type="button"
              (click)="cancel()"
              style="background-color: #3f3f40 !important; border: 0px !important">
              {{'Cancel' | translate}}
            </button>
          </div>
        </form>
      </ng-container>
      <!-- Confirm -->
      <ng-container *ngIf="stateAction === 'CONFIRM'">
        <div class="title" translate>Are you sure?</div>
        <div class="btn-wrap">
          <button (click)="submit()" translate>Yes</button>
          <button style="background-color: #3f3f40 !important; border: 0px !important" (click)="editFee()" translate>
            Cancel
          </button>
        </div>
      </ng-container>
      <!-- Success -->
      <ng-container *ngIf="stateAction === 'SUCCESS'">
        <div class="success-img mt-2">
          <img src="assets/img/mobile/check-white.svg" alt="Success" />
        </div>
        <div class="title" translate>Booking request has been sent</div>
        <div class="spacer"></div>
        <div class="btn-wrap">
          <button style="background-color: #3f3f40 !important; border: 0px !important" (click)="closeModal()" translate>
            Close
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
