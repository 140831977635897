<section class="banners swiper">
  <!-- <div class="swiper-paginate"></div> -->
  <div class="swiper-wrapper">
    <div
      class="banner swiper-slide"
      [style.background-image]="'url(/assets/img/mobile/banner/banner-mobile.png)'"
      (click)="openExternalUrl('https://helpdesk.casterbee.nl/acties/')"></div>
    <div class="banner swiper-slide" [style.background-image]="'url(/assets/img/mobile/banner/banner.png)'">
      <div class="info">
        <h6>
          {{'Connect directly' | translate}}
          <br />
          {{'between Client and Talent' | translate}}
        </h6>
        <p translate>Smart and swift booking of actors & models</p>
      </div>
    </div>
    <div class="banner swiper-slide" [style.background-image]="'url(/assets/img/mobile/banner/banner.png)'">
      <div class="info">
        <h6>
          {{'Connect directly' | translate}}
          <br />
          {{'between Client and Talent' | translate}}
        </h6>
        <p translate>Smart and swift booking of actors & models</p>
      </div>
    </div>
  </div>
</section>
