import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { IRegion } from '@core/interfaces/region';
import { RegionState } from '@core/states';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ModalService } from './modal/services/modal.service';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { LoaderService } from '@core/services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorsService } from '@core/services/errors.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  @Select(RegionState)
  public region$: Observable<IRegion>;

  public regionAvailable = true;
  showLoading = false;

  private destroyed$ = new Subject();

  private initializeSubscription$: Subscription;
  private statusChangeSubscription$: Subscription;
  private languageChangeSubscription$: Subscription;

  public constructor(
    private ccService: NgcCookieConsentService,
    private translate: TranslateService,
    private gtmService: GoogleTagManagerService,
    private modalService: ModalService,
    public loaderService: LoaderService,
    public errService: ErrorsService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  ngAfterViewInit(): void {
    // this.errService.isError.subscribe((data) => {
    //   if (data !== null) {
    //     this.toastr.error('An error occurred, please try again');
    //   }
    // });
  }

  ngAfterViewChecked(): void {
    // this.loaderService.isLoading.subscribe((data) => {
    //   if (this.router?.url !== '') {
    //     const countSegmentOfRoute = this.router.url.split('/');
    //     if (
    //       countSegmentOfRoute.length === 4 &&
    //       countSegmentOfRoute[1] === 'find-jobs' &&
    //       countSegmentOfRoute[2] === 'job'
    //     ) {
    //       this.showLoading = data;
    //       this.cdr.detectChanges();
    //     }
    //   }
    // });
  }

  public ngOnInit(): void {
    this.modalService.init();
    this.translate.addLangs(['en', 'nl']);
    if (localStorage.getItem('language')) {
      this.translate.setDefaultLang(localStorage.getItem('language'));
      this.translate.use(localStorage.getItem('language'));
    } else {
      const languages = navigator?.languages || [];
      const useNL = languages.includes('nl') || languages.includes('be');
      const lang = useNL ? 'nl' : 'en';
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      localStorage.setItem('language', lang);
    }
    this.languageChangeSubscription$ = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.ccService.getConfig().content = this.ccService.getConfig().content || {};
      // Override default messages with the translated ones
      this.ccService.getConfig().content.header = event.translations['cookie.header'];
      this.ccService.getConfig().content.message = event.translations['cookie.message'];
      this.ccService.getConfig().content.dismiss = event.translations['cookie.dismiss'];
      this.ccService.getConfig().content.allow = event.translations['cookie.allow'];
      this.ccService.getConfig().content.deny = event.translations['cookie.deny'];
      this.ccService.getConfig().content.link = event.translations['cookie.link'];
      this.ccService.getConfig().content.policy = event.translations['cookie.policy'];

      this.ccService.destroy(); // remove previous cookie bar (with default messages)
      this.ccService.init(this.ccService.getConfig()); // update config with translated messages
    });

    this.initializeSubscription$ = this.ccService.initialize$.subscribe(async (event: NgcInitializeEvent) => {
      if (event.status === 'allow') {
        await this.gtmService.addGtmToDom();
      }
    });

    this.statusChangeSubscription$ = this.ccService.statusChange$.subscribe(async (event: NgcStatusChangeEvent) => {
      if (event.status === 'allow') {
        await this.gtmService.addGtmToDom();
      }
    });

    this.region$
      .pipe(
        takeUntil(this.destroyed$),
        filter((data) => data?.allowed !== undefined),
        tap((data: IRegion) => (this.regionAvailable = data.allowed)),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.initializeSubscription$.unsubscribe();
    this.statusChangeSubscription$.unsubscribe();
    this.languageChangeSubscription$.unsubscribe();
  }
}
