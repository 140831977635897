import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormsModule as FormCustomModule } from '../../ui/generic/forms/forms.module';
import { DirectiveModule } from '@src/directive/directive.module';
import { ButtonsModule } from '@src/ui/generic/buttons/buttons.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Component
import { MobileModalDialogComponent } from './mobile-modal-dialog/mobile-modal-dialog.component';
import { MobileAuthModalComponent } from './mobile-auth-modal/mobile-auth-modal.component';
import { MobileLanguangeModalComponent } from './mobile-languange-modal/mobile-languange-modal.component';
import { MobileTalentCardComponent } from './mobile-talent-card/mobile-talent-card.component';
import { MobileFilterModalComponent } from './mobile-filter-modal/mobile-filter-modal.component';
import { MobileBannerComponent } from './mobile-banner/mobile-banner.component';
import { MobileTalentSlideComponent } from './mobile-talent-slide/mobile-talent-slide.component';
import { MobileFeatureComponent } from './mobile-feature/mobile-feature.component';
import { MobileCasesSlideComponent } from './mobile-cases-slide/mobile-cases-slide.component';
import { MobilePartnerSlideComponent } from './mobile-partner-slide/mobile-partner-slide.component';
import { MobileJobSlideComponent } from './mobile-job-slide/mobile-job-slide.component';
import { MobileSignUpComponent } from './mobile-sign-up/mobile-sign-up.component';
import { MobileHelpdeskComponent } from './mobile-helpdesk/mobile-helpdesk.component';
import { MobileCategoryComponent } from './mobile-category/mobile-category.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { MobileFooterComponent } from './mobile-footer/mobile-footer.component';
import { MobileNavbarComponent } from './mobile-navbar/mobile-navbar.component';
import { MobileJobDetailComponent } from './mobile-job-detail/mobile-job-detail.component';
import { MobileJobRowsListComponent } from './mobile-job-rows-list/mobile-job-rows-list.component';
import { MobileListComponent } from './mobile-list/mobile-list.component';
import { MobileUserInfoComponent } from './mobile-user-info/mobile-user-info.component';
import { MobilePostJobModalComponent } from './mobile-post-job-modal/mobile-post-job-modal.component';
import { MobileUploadFileComponent } from './mobile-upload-file/mobile-upload-file.component';
import { MobileSuccessComponent } from './mobile-success/mobile-success.component';
import { MobileTalentPhotoalbumOverviewComponent } from './mobile-talent-photoalbum-overview/mobile-talent-photoalbum-overview.component';
import { MobileImagePreviewComponent } from './mobile-image-preview/mobile-image-preview.component';
import { MobileTalentAboutComponent } from './mobile-talent-about/mobile-talent-about.component';
import { RouterModule } from '@angular/router';
import { MobileJobCardComponent } from './mobile-job-card/mobile-job-card.component';
import { MobileJobApplicantComponent } from './mobile-job-applicant/mobile-job-applicant.component';
import { MobileTalentAplicantCardComponent } from './mobile-talent-aplicant-card/mobile-talent-aplicant-card.component';
import { MobileFloatingFooterComponent } from './mobile-floating-footer/mobile-floating-footer.component';
import { MobileTalentContactModalComponent } from './mobile-talent-contact-modal/mobile-talent-contact-modal.component';
import { MobileCancelBookingModalComponent } from './mobile-cancel-booking-modal/mobile-cancel-booking-modal.component';
import { MobileDetailTravelCostModalComponent } from './mobile-detail-travel-cost-modal/mobile-detail-travel-cost-modal.component';
import { MobileAccountProfileEditModalComponent } from './mobile-account-profile-edit-modal/mobile-account-profile-edit-modal.component';
import { MobileAccountProfileAlbumsComponent } from './mobile-account-profile-albums/mobile-account-profile-albums.component';
import { MobileAccountProfileAlbumMediaComponent } from './mobile-account-profile-album-media/mobile-account-profile-album-media.component';
import { MobileAccountProfileMediaFormComponent } from './mobile-account-profile-media-form/mobile-account-profile-media-form.component';
import { MobileAccountProfileMediaFieldComponent } from './mobile-account-profile-media-field/mobile-account-profile-media-field.component';
import { MobileRequestBookModalComponent } from './mobile-request-book-modal/mobile-request-book-modal.component';
import { MobileForgotPasswordModalComponent } from './mobile-forgot-password-modal/mobile-forgot-password-modal.component';
import { MobileMembershipModalComponent } from './mobile-membership-modal/mobile-membership-modal.component';
import { MobileAccountProfileDeleteModalComponent } from './mobile-account-profile-delete-modal/mobile-account-profile-delete-modal.component';
import { MobileRequestVideoModalComponent } from './mobile-request-video-modal/mobile-request-video-modal.component';
import { MobileConfirmBookingModalComponent } from './mobile-confirm-booking-modal/mobile-confirm-booking-modal.component';
import { MobilePostTravelCostModalComponent } from './mobile-post-travel-cost-modal/mobile-post-travel-cost-modal.component';
import { MobileUploadVideoModalComponent } from './mobile-upload-video-modal/mobile-upload-video-modal.component';
import { MobileApplyJobModalComponent } from './mobile-apply-job-modal/mobile-apply-job-modal.component';
import { MobileCarouselComponent } from './mobile-carousel/mobile-carousel.component';
import { MobileCreditModalComponent } from './mobile-credit-modal/mobile-credit-modal.component';
import { MobileInvoiceCardComponent } from './mobile-invoice-card/mobile-invoice-card.component';
import { MobileInvoiceDetailComponent } from './mobile-invoice-detail/mobile-invoice-detail.component';
import { MobileMerchantInfoComponent } from './mobile-merchant-info/mobile-merchant-info.component';
import { MobileAccountMembershipConfirmationModalComponent } from './mobile-account-membership-confirmation-modal/mobile-account-membership-confirmation-modal.component';
import { MobileAccountMembershipModalComponent } from './mobile-account-membership-modal/mobile-account-membership-modal.component';
import { MobileAccountMessagingListComponent } from './mobile-account-messaging-list/mobile-account-messaging-list.component';
import { MobileSuccessModalComponent } from './mobile-success-modal/mobile-success-modal.component';
import { MobileTalentPhotoalbumPreviewComponent } from './mobile-talent-photoalbum-preview/mobile-talent-photoalbum-preview.component';
import { MobileSendBulkMessageModalComponent } from './mobile-send-bulk-message-modal/mobile-send-bulk-message-modal.component';
import { MobileJobNoteDetailComponent } from './mobile-job-note-detail/mobile-job-note-detail.component';
import { MobileCompleteJobModalComponent } from './mobile-complete-job-modal-/mobile-complete-job-modal.component';
import { MobileCompleteJobEditModalComponent } from './mobile-complete-job-edit-modal/mobile-complete-job-edit-modal.component';
import { MobileCompleteJobNoteModalComponent } from './mobile-complete-job-note-modal/mobile-complete-job-note-modal.component';
import { MobileCompleteJobConfirmationModalComponent } from './mobile-complete-job-confirmation-modal/mobile-complete-job-confirmation-modal.component';
import { MobileWithoutTravelCostComponent } from './mobile-without-travel-cost/mobile-without-travel-cost.component';
import { MobileClaimTravelExpensesComponent } from './mobile-claim-travel-expenses/mobile-claim-travel-expenses.component';
import { MobileClaimTravelExpensesRowComponent } from './mobile-claim-travel-expenses-row/mobile-claim-travel-expenses-row.component';
import { MobileClaimTravelExpensesAccountNumberModalComponent } from './mobile-claim-travel-expenses-account-number-modal/mobile-claim-travel-expenses-account-number-modal.component';
import { MobileClaimTravelExpensesIncorrectFeeComponent } from './mobile-claim-travel-expenses-incorrect-fee/mobile-claim-travel-expenses-incorrect-fee.component';
import { MobileAccountProfileVoiceComponent } from './mobile-account-profile-voice/mobile-account-profile-voice.component';

const components = [
  MobileModalDialogComponent,
  MobileAuthModalComponent,
  MobileLanguangeModalComponent,
  MobileTalentCardComponent,
  MobileFilterModalComponent,
  MobileBannerComponent,
  MobileTalentSlideComponent,
  MobileFeatureComponent,
  MobileCasesSlideComponent,
  MobilePartnerSlideComponent,
  MobileJobSlideComponent,
  MobileSignUpComponent,
  MobileHelpdeskComponent,
  MobileCategoryComponent,
  MobileHeaderComponent,
  MobileFooterComponent,
  MobileNavbarComponent,
  MobileJobDetailComponent,
  MobileJobRowsListComponent,
  MobileListComponent,
  MobileUserInfoComponent,
  MobilePostJobModalComponent,
  MobileUploadFileComponent,
  MobileSuccessComponent,
  MobileTalentPhotoalbumOverviewComponent,
  MobileImagePreviewComponent,
  MobileJobCardComponent,
  MobileJobApplicantComponent,
  MobileTalentAplicantCardComponent,
  MobileFloatingFooterComponent,
  MobileTalentContactModalComponent,
  MobileCancelBookingModalComponent,
  MobileDetailTravelCostModalComponent,
  MobileTalentAboutComponent,
  MobileAccountProfileEditModalComponent,
  MobileAccountProfileAlbumsComponent,
  MobileAccountProfileAlbumMediaComponent,
  MobileAccountProfileMediaFieldComponent,
  MobileAccountProfileMediaFormComponent,
  MobileRequestBookModalComponent,
  MobileForgotPasswordModalComponent,
  MobileMembershipModalComponent,
  MobileAccountProfileDeleteModalComponent,
  MobileRequestVideoModalComponent,
  MobileConfirmBookingModalComponent,
  MobilePostTravelCostModalComponent,
  MobileUploadVideoModalComponent,
  MobileApplyJobModalComponent,
  MobileCarouselComponent,
  MobileCreditModalComponent,
  MobileInvoiceCardComponent,
  MobileInvoiceDetailComponent,
  MobileMerchantInfoComponent,
  MobileAccountMembershipModalComponent,
  MobileAccountMembershipConfirmationModalComponent,
  MobileAccountMessagingListComponent,
  MobileAccountMembershipModalComponent,
  MobileAccountMembershipConfirmationModalComponent,
  MobileAccountMessagingListComponent,
  MobileSuccessModalComponent,
  MobileTalentPhotoalbumPreviewComponent,
  MobileSendBulkMessageModalComponent,
  MobileJobNoteDetailComponent,
  MobileCompleteJobEditModalComponent,
  MobileCompleteJobNoteModalComponent,
  MobileCompleteJobModalComponent,
  MobileCompleteJobConfirmationModalComponent,
  MobileWithoutTravelCostComponent,
  MobileClaimTravelExpensesComponent,
  MobileClaimTravelExpensesRowComponent,
  MobileClaimTravelExpensesAccountNumberModalComponent,
  MobileClaimTravelExpensesIncorrectFeeComponent,
];

@NgModule({
  declarations: [...components, MobileAccountProfileVoiceComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    FormCustomModule,
    DirectiveModule,
    ButtonsModule,
    RouterModule,
    InfiniteScrollModule,
  ],
  exports: components,
})
export class SharedMobileModule {}
