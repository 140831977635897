<section class="cases">
  <div class="swiper">
    <div class="swiper-wrapper">
      <div class="card-cases swiper-slide">
        <div class="info">
          <h3>Commercial voor KWF</h3>
          <div class="footer">
            <img src="/assets/img/mobile/cases/actor-cases-1.png" alt="" />
            <div>
              <p translate>Booked talent</p>
              <p>Peter Berkhof</p>
            </div>
          </div>
        </div>
        <img src="/assets/img/mobile/cases/cases-1.png" alt="" />
      </div>
      <div class="card-cases swiper-slide">
        <div class="info">
          <h3>Fotoshoot voor Princess traveler</h3>
          <div class="footer">
            <img src="/assets/img/mobile/cases/actor-cases-3.png" alt="" />
            <div>
              <p translate>Booked talent</p>
              <p>Gavin</p>
            </div>
          </div>
          <div class="footer">
            <img src="/assets/img/mobile/cases/actor-cases-2.png" alt="" />
            <div>
              <p translate>Booked talent</p>
              <p>Kierra Siphron</p>
            </div>
          </div>
        </div>
        <img src="/assets/img/mobile/cases/cases-2.png" alt="" />
      </div>
      <div class="card-cases swiper-slide">
        <div class="info">
          <h3>Video voor Zorg van de Zaak</h3>
          <div class="footer">
            <img src="/assets/img/mobile/cases/actor-cases-4.png" alt="" />
            <div>
              <p translate>Booked talent</p>
              <p>Thuy</p>
            </div>
          </div>
        </div>
        <img src="/assets/img/mobile/cases/cases-3.png" alt="" />
      </div>
    </div>
  </div>
</section>
