<div *appScreenSize="'mobile'">
  <app-mobile-modal-dialog [backgroundColor]="'rgb(123 123 123 / 10%)'" #noteDetailModal>
    <div class="modal-body note-container">
      <h6>Note from talent</h6>
      <p>{{noteValue}}</p>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-3">
        <button (click)="closeDialog()" class="btn btn-bg-gray" style="border-radius: 100px; margin: auto">
          {{'Close' | translate}}
        </button>
      </div>
    </div>
  </app-mobile-modal-dialog>
</div>
<div style="background-color: #3f3f40" *appScreenSize="'dekstop'">
  <div class="modal-body note-container note-desktop">
    <h6>Note from talent</h6>
    <p>{{noteValue}}</p>
  </div>
</div>
