<div class="wrapper">
  <div class="chat-messages-wrapper p-3 h-100">
    <div
      class="col-sm-4 chat-list-container d-flex flex-column py-1"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScrollDown()">
      <app-chat-item
        *ngFor="let chat of chats$ | async"
        [activeChat]="activeChat$ | async"
        (click)="onChatClicked(chat)"
        [user]="chat.sender.id == userId ? chat.recipient : chat.sender"
        [chat]="chat"></app-chat-item>
    </div>

    <div class="messages-wrapper col-12 col-sm-8 py-0 px-0 d-flex flex-column">
      <div class="messages p-0 h-100" *ngIf="activeChat$ | async as activeChat; else emptyChat">
        <app-message-header
          [user]="activeChat.sender.id == userId ? activeChat.recipient : activeChat.sender"
          [chat]="activeChat"></app-message-header>
        <div class="message-list-wrapper py-3 d-flex flex-column flex-grow-1">
          <div class="message-warning">
            <img src="/assets/img/mobile/lamp.svg" alt="" />
            <p>
              {{warningText}}
              <a (click)="goToLink(warningHref)" translate>view the rules</a>
            </p>
          </div>

          <div
            #messagesContainer
            class="message-list d-flex flex-column overflow-y-scroll"
            infiniteScroll
            [scrollWindow]="false"
            (scrolledUp)="loadMoreMessage()"
            (scroll)="onScroll($event)">
            <div
              class="message-container newlines"
              *ngFor="let message of activeChat?.messages"
              [ngClass]="{ own: message.senderId === userId }">
              <div
                class="message px-3 py-2"
                [ngClass]="{ own: message.senderId === userId }"
                gradientName="purple-bright"
                [innerHTML]="convertTextToLink(message.message)"></div>
              <app-message-time [message]="message" [showReadStatus]="message.senderId === userId"></app-message-time>
            </div>
          </div>

          <form [formGroup]="form" class="d-flex mt-4 px-3 align-items-end" (ngSubmit)="sendMessage()">
            <span
              #messageField
              class="message-field flex-grow-1"
              role="textbox"
              [textContent]="message"
              (input)="onChangeMessage($event.target.textContent)"
              (keypress)="onMessageFieldKeyPress($event)"
              (paste)="onMessageFieldPaste($event)"
              contenteditable></span>
            <app-button
              [isBusy]="isBusy"
              [isDisabled]="form.invalid"
              buttonStyle="violent-blue"
              class="ml-2"
              roundedSize="pill"
              style="width: 80px">
              <button
                [disabled]="form.invalid"
                class="w-100 h-100 bg-transparent border-0 text-white px-2"
                translate
                type="submit">
                Send
              </button>
            </app-button>
          </form>
        </div>
      </div>

      <ng-template #emptyChat>
        <div class="messages gradient">
          <div
            [class]="{ 'cover-dutch': messageCover === 'nl', 'cover-english': messageCover === 'en' }"
            class="message-cover h-100 w-100"></div>
        </div>
      </ng-template>
    </div>
  </div>
  <div
    *ngIf="isBusy"
    class="spinner-overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
    <app-spinner></app-spinner>
  </div>
</div>
