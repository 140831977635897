import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-note-input',
  templateUrl: './note-input.component.html',
  styleUrls: ['./note-input.component.scss'],
})
export class NoteInputComponent implements OnInit {
  @Output()
  public applyClicked = new EventEmitter<any>();

  public form = new FormGroup({
    note: new FormControl(),
  });
  constructor() {}

  ngOnInit(): void {}

  public clickApply(skipNote): void {
    this.applyClicked.emit({
      skipNote,
      note: this.form.get('note').value,
    });
  }

  public get noteValue(): string {
    return this.form.get('note').value;
  }
}
