<div class="d-flex flex-column w-100 text-white p-4">
  <div class="container">
    <div [formGroup]="form" class="row justify-content-center">
      <h4 translate>Write a note to the Caster</h4>
      <div class="note-rule">
        <div class="note-section">
          <h6>Do's</h6>
          <ul>
            <li translate>Only give info when relevant to job post</li>
            <li translate>Avoid giving info, just by giving info</li>
          </ul>
        </div>
        <div class="note-section">
          <h6>Don'ts</h6>
          <ul>
            <li translate>Forbidden to provide contact details</li>
            <li translate>Forbidden to negotiate about the fee</li>
            <li translate>Forbidden to ‘beg’ for the job’</li>
          </ul>
        </div>
      </div>
      <app-textarea class="bg-gray w-100 flex-fill border-0 rounded mb-1" formControlName="note"></app-textarea>

      <div class="note-action">
        <app-button [isDisabled]="!noteValue" (click)="clickApply(false)" buttonStyle="artyclick-purple" class="mb-3">
          <div class="px-2" translate>Apply with note</div>
        </app-button>
        <app-button (click)="clickApply(true)" buttonStyle="white-outline" class="mb-3">
          <div class="px-2" translate>Skip and apply</div>
        </app-button>
      </div>
    </div>
  </div>
</div>
