import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { User, UserProfile } from '@core/models';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { JoinModalComponent } from '@src/visitor/modals/components/join-modal/join-modal.component';
import { TalentProfilePDFModalComponent } from '@src/visitor/components/talent/talent-profile-pdf-modal/talent-profile-pdf-modal.component';

import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-about-talent',
  templateUrl: './about-talent.component.html',
  styleUrls: ['./about-talent.component.scss'],
})
export class AboutTalentComponent implements OnChanges {
  @Input()
  public user: User;

  @Input()
  public talent: User;

  @Input()
  public profile: UserProfile;

  @Input()
  public profiles: UserProfile[];

  @Output()
  public offerJobClicked = new EventEmitter();

  public showDetails = false;

  public details = [];

  constructor(public translate: TranslateService, private dialog: MatDialog, private store: Store) {}

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if ('profile' in changes) {
      await this.createDetails(changes.profile.currentValue?.filters);
    }
  }

  public async createDetails(filters: any[]): Promise<void> {
    if (!filters) {
      return;
    }

    // const details = [...filters];
    const groupedDetails = [];

    for (const f of filters) {
      let value = f.value;
      if (f.filterOption != null) {
        value = await this.translate.get(f.filterOption.translateKey).toPromise();
      }

      let filterDetails = groupedDetails.find((d) => d.label === f.filter.label);

      if (!filterDetails) {
        filterDetails = {
          label: f.filter.label,
          translateKey: f.filter.translateKey,
          order: f.filter.order,
          type: f.filter.type,
          unit: f.filter.unit,
          values: [],
        };

        groupedDetails.push(filterDetails);
      }

      filterDetails.values.push(value);
    }

    this.details = groupedDetails.sort((a: any, b: any) => (a.order === b.order ? 0 : a.order > b.order ? 1 : -1));
  }

  public onOfferJobClicked(): void {
    if (!this.user) {
      this.openLogin();
    } else if (this.user.disabled) {
      this.store.dispatch(new Navigate(['/account/disabled']));
    } else if (!this.user.idVerification) {
      this.store.dispatch(new Navigate(['/account/details']));
    } else {
      this.offerJobClicked.emit(null);
    }
  }

  public openLogin(): void {
    if (!this.user) {
      this.dialog.open(JoinModalComponent, {});
      return;
    }
  }

  public exportToPDF(): void {
    if (!!this.profile) {
      this.dialog
        .open(TalentProfilePDFModalComponent, {
          data: {
            profile: this.profile,
          },
        })
        .afterClosed();
    }
  }
}
