import { Location } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@auth/services';
import { MobileAuthModalComponent } from '@shared/mobile-components/mobile-auth-modal/mobile-auth-modal.component';
import { MobilePostJobModalComponent } from '../mobile-post-job-modal/mobile-post-job-modal.component';
import { MobileStorageKey, MobileStorageService } from '../services/mobile-storage.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { tap } from 'rxjs/operators';

interface INavbarMenu {
  name: string;
  icon: string;
  type: 'url' | 'login';
  value?: any;
  active?: boolean;
  marginBottom?: string;
}

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
})
export class MobileNavbarComponent implements OnInit, AfterViewInit {
  @Input() public conditionalNavbar = false;
  @ViewChild('loginModal') private loginModal: MobileAuthModalComponent;
  @ViewChild('postJobModal', { static: false }) private postJobModal: MobilePostJobModalComponent;

  public menus: Array<INavbarMenu>;

  constructor(
    private authService: AuthService,
    private mobileStorage: MobileStorageService,
    private router: ActivatedRoute,
    protected routers: Router,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.setActiveRoute();
  }

  ngAfterViewInit(): void {
    this.router.queryParamMap.pipe(tap()).subscribe((params) => {
      if (params.get('action') === 'login') {
        this.loginModal.openDialog(null);
        this.routers.navigate([], {
          queryParams: {
            action: null,
          },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  public actionIdentifier(menu: INavbarMenu) {
    switch (menu.type) {
      case 'login':
        this.loginModal.openDialog();
        break;
      default:
        break;
    }
  }

  public get showCreateJob(): boolean {
    if (this.mobileStorage.get(MobileStorageKey.activeRole) === 'caster') {
      const activePath = this.location.path();
      if (activePath.split('?')[0] === '/find-jobs' || activePath.split('?')[0] === '/talents') {
        return true;
      } else if (activePath === '/account/details/dashboard') {
        return true;
      }
    }
    return false;
  }

  public openFormJob() {
    this.postJobModal.openDialog();
  }

  public showNavbar(): boolean {
    const activePath = this.location.path();
    const countSegmentOfRoute = activePath.split('/');
    const jobStatusCaster = countSegmentOfRoute.length >= 5 ? countSegmentOfRoute[4] : null;
    const jobStatusTalent = countSegmentOfRoute.length >= 4 ? countSegmentOfRoute[3] : null;
    const jobID = countSegmentOfRoute.length >= 7 ? countSegmentOfRoute[5] : null;
    const rolesID = countSegmentOfRoute.length >= 3 ? countSegmentOfRoute[3] : null;
    const invoiceID = countSegmentOfRoute.length >= 4 ? countSegmentOfRoute[3] : null;
    const jobIDInvoice = countSegmentOfRoute.length >= 6 ? countSegmentOfRoute[4] : null;

    return [
      '/account/jobs/status/' + jobStatusCaster + '/' + jobID + '/details',
      '/account/job-applications/' + jobStatusTalent,
      '/account/job-applications/details/' + jobIDInvoice + '/travel-expenses',
      '/account/job-applications/details/' + jobIDInvoice + '/travel-expenses?action=notc',
      '/account/invoices',
      '/account/invoices/' + invoiceID,
      jobStatusCaster === 'overview' ? null : '/account/jobs/status/' + jobStatusCaster,
      '/account/roles/' + rolesID,
      '/account/messages',
    ].filter((v) => v === activePath).length > 0
      ? false
      : true;
  }

  public setActiveRoute() {
    if (this.authService.isAuthenticated()) {
      this.menus = [
        {
          name: 'Find Jobs',
          icon: 'find-job',
          type: 'url',
          value: '/find-jobs',
          active: this.activeRoute?.value === '/find-jobs' ? true : false,
          marginBottom: '1.5px',
        },
        {
          name: 'Find Talents',
          icon: 'find-talent',
          type: 'url',
          value: '/talents',
          active: this.activeRoute?.value === '/talents' ? true : false,
          marginBottom: '1.5px',
        },
        {
          name: 'Messages',
          icon: 'mail',
          type: 'url',
          value: '/account/messages',
          active: this.activeRoute?.value === '/account/messages' ? true : false,
        },
        {
          name: 'My Jobs',
          icon: 'job',
          type: 'url',
          value: '/account/jobs/status/overview',
          active:
            [
              '/account/jobs/status/overview',
              '/account/jobs/status/ongoing',
              '/account/jobs/status/completed',
              '/account/jobs/status/applied',
              '/account/jobs/status/confirmed',
            ].filter((v) => v === this.activeRoute?.value).length > 0
              ? true
              : false,
        },
      ];
    } else {
      this.menus = [
        {
          name: 'Find Jobs',
          icon: 'find-job',
          type: 'url',
          value: '/find-jobs',
          active: this.activeRoute?.value === '/find-jobs' ? true : false,
          marginBottom: '1.5px',
        },
        {
          name: 'Find Talents',
          icon: 'find-talent',
          type: 'url',
          value: '/talents',
          active: this.activeRoute?.value === '/talents' ? true : false,
          marginBottom: '1.5px',
        },
        {
          name: 'Login',
          icon: 'profile',
          type: 'login',
        },
      ];
    }
  }

  public get activeRoute() {
    const activePath = this.location.path();
    const listRoute = [
      {
        value: '/find-jobs',
        active: activePath.split('?')[0] === '/find-jobs' ? true : false,
      },
      {
        value: '/talents',
        active: activePath.split('?')[0] === '/talents' ? true : false,
      },
      {
        value: '/account/messages',
        active: activePath === '/account/messages' ? true : false,
      },
      {
        value: '/account/jobs/status/overview',
        active:
          [
            '/account/jobs/status/overview',
            '/account/jobs/status/ongoing',
            '/account/jobs/status/completed',
            '/account/jobs/status/applied',
            '/account/jobs/status/confirmed',
          ].filter((v) => v === activePath).length > 0
            ? true
            : false,
      },
      {
        value: '/find-jobs',
        active: activePath.split('?')[0] === '/find-jobs' ? true : false,
      },
      {
        value: '/talents',
        active: activePath.split('?')[0] === '/talents' ? true : false,
        marginBottom: '1.5px',
      },
    ];

    return listRoute.find((res) => res.active);
  }
}
