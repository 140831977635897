import { IUser, MerchantStatus, UserRole, UserType } from '@core/interfaces/user';
import { IUserProfile } from '@core/interfaces/user-profile';
import { Country } from './country.model';
import { Media } from './media.model';
import { Category } from './category.model';
import { Review } from './review.model';
import { UserFilter } from './user-filter.model';
import { UserAlbum } from './user-album.model';
import { BaseModel } from './base-model.model';
import { Language } from './language.model';
import { Transform, TransformFnParams, Type } from 'class-transformer';
import { Membership } from '@core/models/membership.model';
import { IMembership } from '@core/interfaces';
import { IPoint } from '@core/interfaces/point';
import { VatResultDTO } from '@core/dto';
import { UserProfile } from './user-profile.model';
import { AccountBankNumber } from './account-number.model';
import { Voice } from './voice.model';

export class User extends BaseModel implements IUser {
  @Type(() => Country)
  public country?: Country;
  @Type(() => Country)
  public phonenumberCountry?: Country;
  @Transform((role) => role.value.map((i) => UserRole[i]))
  public role: UserRole[];
  @Type(() => Media)
  public photoId?: Media;
  @Type(() => Media)
  public photo?: Media;
  @Type(() => UserAlbum)
  public albums?: UserAlbum[];
  @Type(() => Category)
  public categories?: Category[];
  @Type(() => Review)
  public reviews?: Review[];
  @Type(() => UserFilter)
  public userFilters?: UserFilter[];
  @Type(() => Date)
  public birthday?: Date;
  @Type(() => Media)
  public idVerificationMedia?: Media;
  @Type(() => Language)
  public languages?: Language[];
  @Transform((type: TransformFnParams) => UserType[type.value])
  public userType: UserType;
  @Type(() => Membership)
  public membership?: IMembership;
  @Type(() => Country)
  public vatNumberCountry?: Country;
  @Type(() => VatResultDTO)
  public vatCheckResult?: VatResultDTO;
  @Type(() => AccountBankNumber)
  public accountNumber?: AccountBankNumber;
  @Type(() => Voice)
  public voices?: Voice[];

  public coordinates?: IPoint;

  public id: string;
  public firstName: string;
  public lastName: string;
  public profileName?: string;
  public email: string;
  public city?: string;
  public gender: string;
  public transgender?: boolean;
  public phonenumber?: string;
  public phonenumberCheck?: string;
  public bio?: string;
  public rate?: number;
  public mobileVerification?: boolean;
  public emailVerification?: boolean;
  public idVerification?: boolean;
  public idVerificationMediaId?: boolean;
  public isPro?: boolean;
  public newsletter?: boolean;
  public averageReview?: number;
  public totalReviews?: number;
  public times_profile_changed?: number;
  public coc_nr?: string;
  public companyName?: string;
  public position?: string;
  public totalJobReactions?: number;
  public totalActiveChats?: number;
  public hidden?: boolean;
  public profiles?: UserProfile[];

  public disabled: boolean;
  public vatNumber?: string;
  public street?: string;
  public houseNumber?: string;
  public postalCode?: string;
  public bypassPayment?: boolean;
  public mandate_uid?: string;
  public mandate_status?: string;
  public created: Date;

  get address(): string {
    return [this.city, this.country?.name].join(', ');
  }

  get fullName(): string {
    return [this.firstName, this.lastName].join(' ');
  }

  get formatLanguages(): string {
    return this.languages?.map((lang: Language) => lang.name).join(', ');
  }

  get isCaster(): boolean {
    return this.role?.includes(UserRole.caster);
  }

  get isTalent(): boolean {
    return this.role?.includes(UserRole.talent);
  }

  get isAdmin(): boolean {
    return this.role?.includes(UserRole.admin);
  }

  get phonenumberWithCallingCode(): string {
    return `+ ${this.phonenumberCountry?.callingCode} ${this.phonenumber}`;
  }

  get age(): number {
    const today = new Date();
    const birthDate = new Date(this.birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  get isCompleted(): boolean {
    return !(
      !this.firstName ||
      !this.lastName ||
      // !this.profileName || //Profilename is automatically generated by backend
      !this.photo ||
      !this.birthday ||
      !this.gender ||
      !this.phonenumber ||
      !this.street ||
      !this.houseNumber ||
      !this.postalCode ||
      !this.city ||
      !this.languages ||
      (this.languages && this.languages.length === 0) ||
      !this.phonenumber
    );
  }

  get hasBadge(): boolean {
    return this.membership?.title.includes('Premium') || this.membership?.title.includes('Starter');
  }

  get profileNameLink(): string {
    return this.profileName.replace(' ', '-');
  }
}
