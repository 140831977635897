import { Component, Optional, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import { Job, JobApplication, User } from '@core/models';
import { Store, Select } from '@ngxs/store';
import { RequestToBookJobApplication, AcceptJobApplicationAndCreatePayment, PaymentsState } from '@core/states';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JobInvoice } from '@core/models/job-invoice';

enum stateActionType {
  DEFAULT = 'DEFAULT',
  EDIT = 'EDIT',
  CONFIRM = 'CONFIRM',
  SUCCESS = 'SUCCESS',
}

@Component({
  selector: 'app-request-to-book-modal',
  templateUrl: './request-to-book-modal.component.html',
  styleUrls: ['./request-to-book-modal.component.scss'],
})
export class RequestToBookModalComponent implements OnInit {
  @Select(PaymentsState.paymentSuccessId)
  public paymentSuccessId: Observable<string>;
  public emailIcon = faEnvelope;
  public jobApplication: JobApplication;
  public job: Job;
  public user: User;
  public stateActionType: stateActionType;
  public stateAction: stateActionType = stateActionType.DEFAULT;
  public form: FormGroup = new FormGroup({
    fee: new FormControl(null, [Validators.required, Validators.min(15), Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]),
    buyOut: new FormControl(null, [Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]),
    tc: new FormControl(false),
    tcAmount: new FormControl(null, [Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]),
    isTcMaxAmount: new FormControl(false),
    tcMaxAmount: new FormControl(null, [Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]),
    pc: new FormControl(false),
    isPcMaxAmount: new FormControl(false),
    pcMaxAmount: new FormControl(null, [Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<RequestToBookModalComponent>,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.jobApplication = this.data.jobApplication;
    this.user = this.data.user;
    this.job = this.data.job;
    if (this.job.bypassPayment === false) {
      this.paymentSuccessId.subscribe((value) => {
        if (this.jobApplication.id === value) {
          this.dialogRef.close();
        }
      });
    }

    this.form.get('tc').valueChanges.subscribe((value: boolean) => {
      if (!value) {
        this.form.get('tcAmount').clearValidators();
        this.form.get('tcAmount').reset();
      } else if (value) {
        this.form.get('tcAmount').setValidators([Validators.required, Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]);
        if (this.job !== null && this.job !== undefined) {
          this.form.patchValue({
            tcAmount: this.job.travelingCost,
          });
        } else {
          this.form.patchValue({
            tcAmount: 0.23,
          });
        }
      }
    });

    this.form.get('isTcMaxAmount').valueChanges.subscribe((value: boolean) => {
      if (!value) {
        this.form.get('tcMaxAmount').clearValidators();
        this.form.get('tcMaxAmount').reset();
      } else if (value) {
        this.form.get('tcMaxAmount').setValidators([Validators.required, Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]);
        if (this.job !== null && this.job !== undefined) {
          this.form.patchValue({
            tcMaxAmount: this.job.maxTravelingCost,
          });
        }
      }
    });

    this.form.get('pc').valueChanges.subscribe((value: boolean) => {
      if (!value) {
        this.form.get('isPcMaxAmount').reset();
      }
    });

    this.form.get('isPcMaxAmount').valueChanges.subscribe((value: boolean) => {
      if (!value) {
        this.form.get('pcMaxAmount').clearValidators();
        this.form.get('pcMaxAmount').reset();
      } else if (value) {
        this.form.get('pcMaxAmount').setValidators([Validators.required, Validators.pattern(/^[0-9]*\.?[0-9]*?$/)]);
        if (this.job !== null && this.job !== undefined) {
          this.form.patchValue({
            pcMaxAmount: this.job.maxParkingCost,
          });
        }
      }
    });

    if (this.job !== null && this.job !== undefined) {
      this.form.patchValue({
        fee: this.job.rate,
        buyOut: this.job.extraFees,
        tc: this.job.hasTravelCosts,
        tcAmount: this.job.travelingCost,
        isTcMaxAmount: this.job.maxTravelingCost > 0 ? true : false,
        tcMaxAmount: this.job.maxTravelingCost,
        pc: this.job.parkingCost,
        isPcMaxAmount: this.job.parkingCost,
        pcMaxAmount: this.job.maxParkingCost,
      });
    }
  }

  public confirmToSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.stateAction = stateActionType.CONFIRM;
  }

  public async submit(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    try {
      const jobInvoiceItem: JobInvoice = {
        fee: this.form.get('fee')?.value,
        buyOut: this.form.get('buyOut')?.value,
        tc: this.form.get('tc')?.value,
        pc: this.form.get('pc')?.value,
        tcAmount: this.form.get('tcAmount')?.value,
        tcMaxAmount: this.form.get('tcMaxAmount')?.value,
        pcMaxAmount: this.form.get('pcMaxAmount')?.value,
      };

      await this.store.dispatch(
        new RequestToBookJobApplication(
          this.user.id,
          this.jobApplication.jobId,
          this.jobApplication.id,
          jobInvoiceItem,
        ),
      );
    } catch (error) {
      console.error('Failed to accept applicant', error);
    }

    this.stateAction = stateActionType.SUCCESS;
  }

  public async acceptJobApplication(): Promise<void> {
    try {
      if (this.job.bypassPayment) {
        await this.store.dispatch(
          new RequestToBookJobApplication(this.user.id, this.jobApplication.jobId, this.jobApplication.id),
        );
      } else {
        const returnUrl = `${environment.baseUrl}/account/jobs/status/open`;
        await this.store.dispatch(
          new AcceptJobApplicationAndCreatePayment(
            this.user.id,
            this.jobApplication.user.id,
            this.jobApplication.jobId,
            this.jobApplication.id,
            returnUrl,
            !this.job.bypassPayment,
          ),
        );
      }
    } catch (error) {
      console.error('Failed to accept applicant', error);
    }
    this.stateAction = stateActionType.SUCCESS;
  }

  public acceptJobApplicationAndCreatePayment(): void {
    if (this.job.bypassPayment) {
      this.dialogRef.close();
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public editFee(): void {
    this.stateAction = stateActionType.EDIT;
  }

  public cancel(): void {
    this.stateAction = stateActionType.DEFAULT;
  }
}
