<ng-container *ngIf="profiles && profile">
  <div class="tab-controls row">
    <app-button
      class="mb-1 mb-sm-0"
      (click)="selectProfile(p)"
      *ngFor="let p of profiles; let i = index"
      [buttonStyle]="p.id == profile.id ? 'purple' : 'white-outline'">
      <span class="px-4 py-1" translate>{{ p.category?.translateKeySingular }}</span>
    </app-button>
  </div>

  <div class="tabs mt-4">
    <div class="media-type-selector mb-3">
      <ng-container *ngFor="let type of mediaTypes">
        <div
          (click)="selectedMediaType = type"
          *ngIf="hasAlbumType(type.key)"
          [class.active]="selectedMediaType == type"
          [ngClass]="type.key"
          class="type">
          <app-icon [icon]="type.icon" fill="white" class="d-inline-block mr-1"></app-icon>
          <span style="position: relative; top: -2px">{{ type.title }}</span>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <ng-container *ngIf="selectedMediaType.key === 'voice'">
        <ng-container *ngFor="let voice of profile.user.voices">
          <div class="col-lg-6 voice-item">
            <p>{{ voice.title }}</p>
            <ol>
              <li><audio [src]="voice.url" controls></audio></li>
              <li>
                <button (click)="downloadVoice(voice)">
                  <app-icon icon="trash" fill="white" icon="/assets/img/download.svg"></app-icon>
                </button>
              </li>
            </ol>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let album of profile.albums">
        <ng-container *ngFor="let media of album.media">
          <div
            class="col-12 col-sm-6 col-lg-4 mb-4"
            [ngClass]="media.mediaType"
            *ngIf="media.mediaType == selectedMediaType.key">
            <app-image
              (click)="openMedia(media)"
              class="cover rounded-sm square-cover d-flex cursor-pointer"
              [image]="media.thumbUrl"></app-image>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
