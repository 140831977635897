import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Filter, Job, JobApplication, PaginatedResults, UserProfile } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { AuthState, FetchAllJobApplications, FetchJob, JobsState, UserState } from '@core/states';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { SearchState, UpdateRecentlyViewed } from '@src/visitor/states/search';
import { IUser, UserRole } from '@core/interfaces';
import { ApplyComponent } from '@shared/components/jobs/apply/apply.component';
import { JwtToken } from '@auth/models';
import { ReportUserModalComponent } from '@src/visitor/modals/components/report-user-modal/report-user-modal.component';
import { JobFilter } from '@core/models/job-filter.model';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { AuthService } from '@auth/services';
import { SplideOptions } from '@splidejs/splide';

@Component({
  selector: 'app-job-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class JobDetailsComponent implements OnInit {
  @Output()
  public cancelClicked = new EventEmitter();

  @Select(SearchState.recentlyViewedJobs)
  public recentlyViewed$: Observable<Job[]>;

  @Select(JobsState.jobApplications)
  public userJobs$: Observable<PaginatedResults<JobApplication>>;

  @Select(UserState.profiles)
  public profiles$: Observable<UserProfile[]>;

  public job$: Observable<Job>;

  public formSend = false;
  public isBusy = false;
  public copied = false;
  public jobFilters: any[] = [];
  public jobId: string;
  public mobileVersion: boolean;
  public slideOption = {
    type: 'fade',
    perPage: 1,
  } as SplideOptions;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<JobDetailsComponent>,
    private store: Store,
    private authService: AuthService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.jobId = this.data?.jobId;

    if (window.innerWidth <= 500) {
      this.mobileVersion = true;
    } else {
      this.mobileVersion = false;
    }
  }

  public get authenticated(): boolean {
    return this.store.selectSnapshot(AuthState.authenticated);
  }

  public get user(): IUser {
    return this.store.selectSnapshot(UserState.user);
  }

  public get token(): JwtToken {
    return this.store.selectSnapshot(AuthState.jwtToken);
  }

  public get userIsTalent(): boolean {
    return this.token?.getClaim('roles').includes(UserRole.talent);
  }

  /**
   * Check if the logged in user has already applied to the job
   */
  public get userAlreadyApplied$(): Observable<boolean> {
    this.isBusy = true;
    const job = this.store.selectSnapshot(JobsState.job);
    const isApplyJob = this.userJobs$.pipe(
      filter<PaginatedResults<JobApplication>>((jobs: PaginatedResults<JobApplication>) => jobs !== null),
      map<PaginatedResults<JobApplication>, JobApplication[]>((jobs: PaginatedResults<JobApplication>) => jobs.results),
      map<JobApplication[], JobApplication[]>((jobs: JobApplication[]) =>
        jobs.filter((jobApplication: JobApplication) => jobApplication.jobId === job.id),
      ),
      map<JobApplication[], boolean>((jobs: JobApplication[]) => jobs.length > 0),
    );
    isApplyJob.subscribe((res) => {
      this.isBusy = false;
    });
    return isApplyJob;
  }

  public async ngOnInit(): Promise<void> {
    this.job$ = this.store.dispatch(new FetchJob(this.jobId)).pipe(
      switchMap(() => this.store.select(JobsState.job)),
      tap(async (job) => {
        if (job != null) {
          this.jobFilters = await this.details(job.category.filters, job.filters);
          if (this.mobileVersion) {
            this.router
              .navigate([`/find-jobs`], {
                queryParams: {
                  orderBy: 'created;desc;' + job.id,
                },
              })
              .then(() => {
                this.closeModal();
                window.location.reload();
              });
          }
        }
      }),
    );
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public async applyToJob(): Promise<void> {
    if (this.authService.isDisabled()) {
      this.closeModal();
      await this.store.dispatch(new Navigate(['/account/disabled'])).toPromise();
      return;
    }
    if (!this.authenticated || !this.userIsTalent) {
      return;
    }

    const userId = this.token.getClaim('sub');
    const job = this.store.selectSnapshot(JobsState.job);

    await this.dialog
      .open(ApplyComponent, {
        data: {
          job,
          user: this.user,
          // userId,
          // userGender: this.user.gender,
          // userTransgender: this.user.transgender,
        },
      })
      .afterClosed()
      .toPromise();

    // this.dialogRef.close();

    this.store.dispatch(new FetchAllJobApplications(userId));
  }

  public getTotalJobs(profiles: UserProfile[]): number {
    return profiles.reduce((prev: number, curr: UserProfile) => prev + (curr.totalJobs ?? 0), 0);
  }

  public async details(categoryFilters: Filter[], filters: JobFilter[]): Promise<any[]> {
    const mappedCategoryFilters = [];
    for (const categoryFilter of categoryFilters) {
      let value;
      if (categoryFilter.type === 'slider') {
        const sliderValue = filters.find((item) => item.filter.id === categoryFilter.id);
        if (sliderValue && sliderValue.lowValue != null) {
          if (sliderValue.lowValue === categoryFilter.min && sliderValue.highValue === categoryFilter.max) {
            value = 'No preference';
          } else {
            value = `${sliderValue.lowValue} - ${sliderValue.highValue} ${sliderValue.filter.unit}`;
          }
        }
      }

      if (categoryFilter.type === 'number') {
        const numberValue = filters.find((item) => item.filter.id === categoryFilter.id);
        if (numberValue && numberValue.lowValue != null) {
          value = `${numberValue.lowValue}`;
        }
      }
      if (categoryFilter.type === 'text') {
        const textValue = filters.find((item) => item.filter.id === categoryFilter.id);
        if (textValue && textValue.value != null) {
          value = `${textValue.value}`;
        }
      }

      if (categoryFilter.type === 'color') {
        const colorValue = filters.find((item) => item.filter.id === categoryFilter.id);
        if (colorValue && colorValue.lowValue != null) {
          value = [colorValue.lowValue, colorValue.highValue];
        }
      }

      if (categoryFilter.type === 'checkbox') {
        const values = categoryFilter.filterOptions.filter(
          (categoryFilterOption) =>
            filters.findIndex((jobFilter) => categoryFilterOption.id === jobFilter?.filterOption?.id) > -1,
        );
        if (values.length < categoryFilter.filterOptions.length) {
          const mappedValues = await Promise.all(
            values.map(async (item) => await this.translate.get(item.translateKey).toPromise()),
          );
          value = mappedValues.join(', ');
        } else {
          value = 'No preference';
        }
      }
      const jobFilters = filters.filter((item) => item.filter.id === categoryFilter.id);

      if (value && value !== 'No preference') {
        mappedCategoryFilters.push({
          label: categoryFilter.label,
          translateKey: categoryFilter.translateKey,
          type: categoryFilter.type,
          value,
        });
      }
    }
    return mappedCategoryFilters;
  }

  public async openJobModal(job: Job): Promise<void> {
    this.closeModal();

    await this.dialog
      .open(JobDetailsComponent, {
        data: {
          jobApplication: {
            job,
          },
          jobId: job.id,
        },
      })
      .afterClosed()
      .toPromise();
    this.store.dispatch(new UpdateRecentlyViewed(job));
  }

  public openReportModal(): void {
    if (!this.authenticated) {
      this.openLoginModal();
      return;
    }
    const job = this.store.selectSnapshot(JobsState.job);
    this.dialog.open(ReportUserModalComponent, {
      data: {
        talentId: job.user.id,
        jobId: job.id,
      },
    });
  }

  public openLoginModal(): void {
    this.closeModal();
    this.router.navigateByUrl(`/login?route=${encodeURIComponent(this.router.url)}`);
  }

  public upgradeAccount(): void {
    this.store.dispatch(new Navigate(['/account/details/membership']));
  }

  public shareUrl(): void {
    const selBox = document.createElement('textarea');
    selBox.style.opacity = '0';
    selBox.value = window.location.href;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
  }

  checkProfilesCompletion(): Observable<boolean> {
    return this.profiles$.pipe(
      map((profiles) => {
        if (profiles !== null) {
          return profiles.filter((profile) => profile.completed).length > 0;
        }
        return false;
      }),
    );
  }
}
